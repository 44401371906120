import { useState } from "react";
import { Button, Group, Paper, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { modals } from "@mantine/modals";

const ShiftEventModal = ({ table, selectedRows, onShiftEvent }: { table: any; selectedRows: any; onShiftEvent: any }) => {
    selectedRows.sort((a: any, b: any) => new Date(a.servertime).getTime() - new Date(b.servertime).getTime());

    const initialDate = new Date(`${selectedRows[0].date} ${selectedRows[0].startTime}`);
    const [currentDate, setCurrentDate] = useState(initialDate);
    const [timeDifference, setTimeDifference] = useState(0); // Difference in milliseconds

    const handleDateChange = (newDate: Date) => {
        const diff = newDate.getTime() - initialDate.getTime();
        setCurrentDate(newDate);
        setTimeDifference(diff);
    };

    const formatTimeDifference = (ms: number) => {
        const totalSeconds = Math.abs(ms) / 1000;
        const seconds = Math.floor(totalSeconds) % 60;
        const minutes = Math.floor(totalSeconds / 60) % 60;
        const hours = Math.floor(totalSeconds / 3600) % 24;
        const days = Math.floor(totalSeconds / (3600 * 24));
    
        return `${days > 0 ? `${days}d ` : ""}${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <Paper miw={400} mih={450} p={30}>
            <DateTimePicker
                label="Pick date and time"
                placeholder="Pick date and time"
                maw={400}
                mx="auto"
                withSeconds
                dropdownType="popover"
                value={currentDate}
                onChange={(value: Date) => handleDateChange(value)}
            />
            <Text color="dimmed" p={20}>Time difference: {formatTimeDifference(timeDifference)}</Text>
            <Group m={20} align="center" w={'100%'} grow>
                <Button
                    onClick={() => {
                        onShiftEvent(selectedRows, timeDifference);
                        modals.closeAll();
                    }}
                    disabled={!timeDifference}
                >
                    Confirm
                </Button>
                <Button color="red" onClick={() => modals.closeAll()}>Cancel</Button>
            </Group>
        </Paper>
    );
};

export default ShiftEventModal;
