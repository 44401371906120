import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const logStatuses = {
    'on_duty': 'ON',
    'off_duty': 'OFF',
    'sleep': 'SB',
    'driving': 'DRV',
    'intermediate': 'I',
    'PC': 'PC',
    'YM': 'YM',
    'ignition_stop': 'PD',
    'ignition_start': 'PU',
    'login': 'LI',
    'test_event': 'TE'
}

function calculateDuration(startTime,endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const diffInMs = endDate - startDate;

    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    const formattedHours = String(hours).padStart(2,'0');
    const formattedMinutes = String(minutes).padStart(2,'0');
    const formattedSeconds = String(seconds).padStart(2,'0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export async function parseELDDataToTableFormat(eldLog) {
    const tableData = [];
    let previousDate = null;
    let duration = '';
    // eldLog = eldLog.reverse()
    for (let i = 0; i < eldLog.length; i++) {
        const date = new Date(eldLog[i].shiftedTime);
        if (i < eldLog.length - 1) {
            const nextEventTime = new Date(eldLog[i + 1].shiftedTime);
            duration = calculateDuration(date,nextEventTime)
        }
        const dist = () => {
            if (i === 0) {
                return 0
            } else {
                return eldLog[i].totalDistance / 1000 - eldLog[i - 1].totalDistance / 1000
            }
        }

        previousDate = date;
        const rowData = {
            id: String(eldLog[i].id),
            status: logStatuses[eldLog[i].status],
            vehicle: eldLog[i].truckid,
            date: `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`,
            startTime: `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`,
            odometer: eldLog[i].totalDistance / 1000,
            engineHours: eldLog[i].engineHours,
            duration: String(duration),
            distance: Number(dist().toFixed(3)),
            location: (<a
                href={`https://www.google.com/maps/search/?api=1&query=${eldLog[i].latitude},${eldLog[i].longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit',textDecoration: 'none' }}
            >
                {eldLog[i].location}
            </a>),
            latitude: eldLog[i].latitude,
            longitude: eldLog[i].longitude,
            elapsedEngineHours: 0,
            elapsedOdometer: 0,
            isActiveStatus: '',
            origin: '',
            note: eldLog[i].note,
            totalEngineHours: 0,
            isCreating: true,
            assignedcompanyid: eldLog[i].assignedcompanyid,
            deviceid: eldLog[i].deviceid,
            driverid: eldLog[i].driverid,
            truckid: eldLog[i].truckid,
            speed: eldLog[i].speed,
            sourceTable: eldLog[i].sourceTable,
            data: eldLog[i].data,
            timezone_truck: eldLog[i].timezone_truck,
            sequence_id_number: eldLog[i].sequence_id_number,
            record_status: eldLog[i].record_status,
            record_origin: eldLog[i].record_origin,
            event_code: eldLog[i].event_code,
            angle: eldLog[i].angle,
            seat_belt_status: eldLog[i].seat_belt_status,
            satellites: eldLog[i].satellites,
            fuel_level: eldLog[i].fuel_level,
            servertime: eldLog[i].servertime
        };

        tableData.push(rowData);
    }

    return tableData;
}