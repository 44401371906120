import { Box, Button, Group, Select, Stack, TextInput } from "@mantine/core"
import { modals } from '@mantine/modals';
import { useForm } from "@mantine/form"
import { VehicleCreate } from "./Vehicles.types";
import { handleCreateTruckSubmit } from "./Vehicles.utils";
import { CompanySettings } from "../Companies/Companies.types";

const CreateVehiceForm = () => {
  const form = useForm<VehicleCreate>({
    initialValues: {
      unitnumber: '',
      licenseplateNumber: '',
      vin: '',
      deviceserialnumber: '',
      assignedcompanyid: '',
      assigneddriverid: '',
      imei: 0o12332154631
    },
    validate: {
      unitnumber: (value) => (value !== "" ? null : "Invalid unitnumber"),
    },
  });
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') || '{}').companies : []
  const companiesArray = userData.map((company: CompanySettings) => ({value: company.id, label: company.carrierName}))

  return (
    <Box
      component="form"
      maw={800}
      mx="auto"
      onSubmit={form.onSubmit((values: VehicleCreate, _event) => {handleCreateTruckSubmit(values)})}
      onReset={form.onReset}
    >
      <Group position='center' align='flex-start'>
        <Stack w={300} align='center' justify='flex-start' className="companies-table-column" >
          <TextInput
            required
            w={250}
            placeholder="unit number"
            label="Unit Number"
            type="number"
            {...form.getInputProps(`unitnumber`)}
            step={1}
          />
          <TextInput
            w={250}
            label="License plate number:"
            type="number"
            placeholder="Contact"
            required
            step={2}
            mt="sm"
            {...form.getInputProps(`licenseplateNumber`)}

          />
          <TextInput
            w={250}
            label="VIN number:"
            mt="sm"
            {...form.getInputProps(`vin`)}
            placeholder="VIN number"
            required
            step={3}
          />
        </Stack>
        <Stack w={300} justify='flex-start' align='center' className="companies-table-column" >
          <TextInput
            w={250}
            label="Device number:"
            mt="sm"
            required
            step={4}
            placeholder="Device number"
            {...form.getInputProps(`deviceserialnumber`)}
          />
          <Select
            w={250}
            label="Company:"
            placeholder="Pick one"
            mt="sm"
            data={companiesArray}
            {...form.getInputProps(`assignedcompanyid`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('assignedcompanyid', value);
              }
            }}
            required
            step={5}
          />
          <Select
            w={250}
            label="Assingned driver:"
            placeholder="Pick one"
            mt="sm"
            data={[]}
            {...form.getInputProps(`assigneddriverid`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('assigneddriverid', value);
              }
            }}
            step={6}
          />
        </Stack>
      </Group>
      <Group
        mt="xl"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          marginTop: '50px'
        }}>
        <Button
          size="md"
          color='red'
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          size="md"
          type="submit"
          color='green'
        >
          Create New Vehicle
        </Button>
      </Group>
    </Box>
  )
}
export default CreateVehiceForm