
import dayjs from 'dayjs';

export const eventCodes = {
    'on_duty': 'ON',
    'off_duty': 'OFF',
    'sleep': 'SB',
    'driving': 'DRV',
    'intermediate': 'I',
    'PC': 'PC',
    'YM': 'YM',
    'ignition_stop': 'PD',
    'ignition_start': 'PU',
    'login': 'LI',
    'test_event': 'TE'
};

const basicEvents = ['ON','OFF','SB','DRV'];
const allowedEvents = [
    'on_duty',
    'off_duty',
    'sleep',
    'driving',
    'PC',
    'YM',
    // 'ignition_stop', // for demo needs
    // 'ignition_start',
    'login',
    'test_event'
];
const pointStatuses = ['PC','YM','ignition_stop','ignition_start','login'];
const statusColors = {
    'PC': '#a6ac47',
    'YM': '#3ace34',
    'ignition_stop': '#ff0000',
    'ignition_start': '#00ff00',
    'login': '#0000ff',
};

export function parseELDDataToApexFormat(eldLog) {
const reversedEldLog = eldLog.reverse();
    const apexData = [];
    const pointers = [];
    const durationByStatus = { 'OFF': 0,'SB': 0,'DRV': 0,'ON': 0 };
    const sortOrder = { 'OFF': 1,'SB': 2,'DRV': 3,'ON': 4 };
    const timezoneOffsetInMilliseconds = new Date().getTimezoneOffset() * 60 * 1000; // to compensate browser local timezone
    console.log(timezoneOffsetInMilliseconds);
    
    let previousEventTime = null;
    let previousEventType = null;

    reversedEldLog.forEach((line,i) => {
        // const dateTime = dayjs(line.shiftedTime).valueOf();
        const dateTime = new Date(line.shiftedTime).getTime() - timezoneOffsetInMilliseconds;
        if (allowedEvents.includes(line.status)) {
            if (previousEventTime !== null && previousEventType !== null) {
                const duration = dateTime - previousEventTime;
                durationByStatus[previousEventType] += duration;

                const eventData = {
                    x: previousEventType,
                    y: [previousEventTime,dateTime]
                };

                if (basicEvents.includes(previousEventType)) {
                    apexData.push(eventData);
                } else {
                    pointers.push(eventData);
                }
            }
            previousEventTime = dateTime;
            previousEventType = eventCodes[line.status];
        }
    });

    basicEvents.forEach(status => {
        const time = dayjs(status.shiftedTime).valueOf();
        const yValue = reversedEldLog[0] !== null ? [time,time] : [0,0];
        apexData.push({
            x: status,
            y: yValue
        });
    });

    apexData.sort((a,b) => sortOrder[a.x] - sortOrder[b.x]);

    const sortedDurations = [];
    Object.keys(sortOrder).forEach(status => {
        if (durationByStatus[status] !== undefined) {
            sortedDurations.push({
                status: status,
                duration: durationByStatus[status]
            });
        }
    });

    const pointAnnotations = pointers.map((pointer) => {
        if (pointer.x === 'PD') return { x: pointer.y[0],borderColor: '#e37d7d',strokeDashArray: 0,}
        if (pointer.x === 'PU') return { x: pointer.y[0],borderColor: '#82f47c',strokeDashArray: 0 }
    });

    return { apexData,durationByStatus: sortedDurations,pointAnnotations };
}