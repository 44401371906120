export enum Environment {
    Development = 'development',
    Production = 'production',
}

const config = {
    [Environment.Development]: {
        API_BASE_URL: 'http://localhost:3000',
    },
    [Environment.Production]: {
        API_BASE_URL: 'https://logifyeld.com',
    },
};

const currentEnvironment = process.env.NODE_ENV as Environment || Environment.Development;

const currentConfig = config[currentEnvironment];

export const API_BASE_URL = currentConfig.API_BASE_URL;
