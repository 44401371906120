import { UIStatus } from '../types/UIStatusType';
import { DBStatus } from '../types/DBStatusType';

export const logStatusesDBToUI: Record<DBStatus, UIStatus> = {
    'on_duty': 'ON',
    'driving': 'DRV',
    'ignition_stop': 'PD',
    'ignition_start': 'PU',
    'login': 'LI',
    'test_event': 'TE',
    'off_duty': 'OFF',
    'sleep': 'SB',
    'intermediate': 'I',
    'PC': 'PC',
    'YM': 'YM',
};

export const logStatusesUIToDB: Record<UIStatus, DBStatus> = {
    'ON': 'on_duty',
    'DRV': 'driving',
    'PD': 'ignition_stop',
    'PU': 'ignition_start',
    'LI': 'login',
    'TE': 'test_event',
    'OFF': 'off_duty',
    'SB': 'sleep',
    'I': 'intermediate',
    'PC': 'PC',
    'YM': 'YM',
};

export const mapDBStatusToUI = (status: DBStatus): UIStatus => {
    return logStatusesDBToUI[status];
};

export const mapUIStatusToDB = (status: UIStatus): DBStatus => {
    return logStatusesUIToDB[status];
};

