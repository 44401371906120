import { Button, Divider, Flex, Text } from "@mantine/core"
import { modals } from "@mantine/modals";
import {
    IconCalculator,
    IconCodeMinus,
    IconCodePlus,
    IconFileDownload,
    IconFileX,
    IconGraph,
    IconRefresh,
    IconRestore,
    IconSwitchHorizontal,
    IconTruckReturn,
    IconUser
} from "@tabler/icons-react"
import ShiftEventeModal from "../Modals/ShiftEventeModal";

const ELDLogsTableTopBtns = ({ table, onInsertEvent, onRemoveEvent, onShiftEvent }: any) => {
    const selectedRows = table.getSelectedRowModel().rows.map((row: any) => row.original);

    const openShiftModal = () => {
        modals.open({
            title: "Confirm Shift Event",
            children: (
                <ShiftEventeModal
                    table={table}
                    selectedRows={selectedRows}
                    onShiftEvent={(rows: any, timeDifference: number) => {
                        onShiftEvent(rows, timeDifference);
                    }}
                />
            ),
        });
    };

    return (
        <Flex
            mih={50}
            gap="xs"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            pl={20}
        >
            <Flex direction="column">
                <Button leftIcon={<IconFileX />} disabled color="green" size="xs" >Export CSV</Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button leftIcon={<IconGraph />} disabled size="xs" >Build chart</Button>
                <Button leftIcon={<IconRestore />} disabled size="xs" >Restore chart</Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button leftIcon={<IconUser />} disabled size="xs" >Reasign driver</Button>
                <Button leftIcon={<IconTruckReturn />} disabled size="xs" >Reasign vehicle</Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button
                    leftIcon={<IconSwitchHorizontal />}
                    onClick={openShiftModal}
                    disabled={selectedRows.length === 0}
                    size="xs"
                >
                    Shift ({selectedRows.length})
                </Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button leftIcon={<IconFileDownload />} disabled size="xs" >Download output file</Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button leftIcon={<IconCodePlus />} onClick={onInsertEvent} size="xs" >Insert event</Button>
                <Button
                    leftIcon={<IconCodeMinus />}
                    size="xs"
                    onClick={() => onRemoveEvent(selectedRows)}
                    disabled={selectedRows.length === 0}
                >
                    Remove event ({selectedRows.length})
                </Button>
            </Flex>
            <Divider orientation="vertical" />
            <Flex direction="column" gap="xs">
                <Button leftIcon={<IconRefresh />} disabled size="xs" >Restore original data</Button>
                <Button leftIcon={<IconCalculator />} disabled size="xs" >Recalculate S/N</Button>
            </Flex>
        </Flex>
    )
}
export default ELDLogsTableTopBtns