import { Progress } from '@mantine/core';
import styles from './LiveTrackerDrawer.module.css';

const LiveTrackingDrawerDiagnostics = ({ truckData }: any) => {

  return (
    <div className={styles.liveTrackingDrawerDiagnostics}>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Seatbelt (Driver)</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{Boolean(Number(truckData.seat_belt_status)) ? 'Bukled' : 'Unbuckled'}</div>
      </div>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Engine Hours</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{truckData.EngineHours}</div>
      </div>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Odometer</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{truckData.TotalDistance} mi</div>
      </div>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Fuel level</div>
        <Progress value={truckData.fuel_level}  color="green" size="xl" style={{ width: '25%' }}/>{truckData.fuel_level}%
      </div>
    </div>
  )
}
export default LiveTrackingDrawerDiagnostics