import { DateTime } from "luxon";

export function convertTimeStringTo24HourFormat(time: string) {
    const [timePart, meridian] = time.split(" "); 
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (meridian === "PM" && hours !== 12) {
        hours += 12;
    } else if (meridian === "AM" && hours === 12) {
        hours = 0;
    }

    return `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}


export const formatDateWithLuxon = (inputDate: string) => {
    const date = DateTime.fromFormat(inputDate, "M/d/yyyy");
    return date.toFormat("MM/dd/yyyy");
};

export function formatMilliseconds(milliseconds: number) {
    let totalSeconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;
  
    return `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
  }
