import { BASE_URL } from './environments';

export const fetchWithBaseUrl = async (
  endpoint: string,
  options?: RequestInit
): Promise<Response> => {
  const url = `${BASE_URL}${endpoint}`;
  return fetch(url, options);
};

export const fetchDriverLog = async (
  id: string,
  dateTo: string,
  dateFrom: string,
  companyTimezone: string,
  accessToken: string,
  refreshToken: string,
  companyName: string
): Promise<any[] | null> => {
  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() || null;
    }
    return null;
  };
  
  const fetchWithAuth = async (url: string, token: string): Promise<Response> => {
    const sessionId = getCookie('logifySessionId');
    return fetch(`${BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...(sessionId ? { 'Session-Id': sessionId } : {}),
      },
      credentials: 'include',
    });
  };

  const refreshAccessTokenManually = async (refreshToken: string): Promise<string | null> => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) {
        console.error('Failed to refresh token:', await response.text());
        return null;
      }

      const data = await response.json();
      return data.accessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  };

  const isTokenValid = (token: string): boolean => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Date.now() / 1000;
      return payload.exp > currentTime;
    } catch (e) {
      console.error('Error decoding token:', e);
      return false;
    }
  };

  try {
    if (!isTokenValid(accessToken)) {
      console.log('Access token is invalid, attempting to refresh...');
      const newAccessToken = await refreshAccessTokenManually(refreshToken);
      if (!newAccessToken) {
        console.error('Failed to refresh access token');
        return null;
      }
      accessToken = newAccessToken;
    }

    const companyResponse = await fetchWithAuth(`/api/v1/company/${companyName}`, accessToken);

    if (!companyResponse.ok) {
      console.error('Failed to select company:', await companyResponse.text());
      return null;
    }

    const url = `/api/v1/report/driver?driverid=${id}&to=${dateTo}&from=${dateFrom}&timeZone=${companyTimezone}&companyName=${companyName}`;
    const response = await fetchWithAuth(url, accessToken);

    if (!response.ok) {
      console.error('Error fetching driver log:', await response.text());
      return null;
    }

    const data = (await response.json());

    data.forEach((item: any) => {
      const servertime = new Date(item.servertime);
      item.timezone_truck = item.timezone_truck || companyTimezone;
      item.shiftedTime = new Date(servertime).toLocaleString('en-US', {
        timeZone: companyTimezone,
      });
    });

    return data;
  } catch (error) {
    console.error('Error fetching driver log:', error);
    return null;
  }
};
