import { UserLog } from '../types/ELDLog.types';
import { convertTimeStringTo24HourFormat, formatDateWithLuxon } from '../utils/timeConverters';

const formatDateTime = (serverTime: string, timeZone: string) => {
  const localizedTime = new Date(serverTime).toLocaleString("en-US", { timeZone });
  const [date, time] = localizedTime.split(',');
  return {
    date: date.trim(),
    time: time.trim(),
  };
};

export const handleShiftEvent = (
  selectedRows: UserLog[],
  data: UserLog[],
  setData: (data: UserLog[]) => void,
  setDeletedRows: React.Dispatch<React.SetStateAction<UserLog[]>>,
  setChangedCells: React.Dispatch<React.SetStateAction<string[]>>,
  table: any,
  timeDifference: number
): void => {
  const activeCompany = localStorage.getItem('activeCompany');
  if (!activeCompany) {
    console.error('Active company not found in localStorage');
    return;
  }
  const selectedIds = table.getSelectedRowModel().rows.map((row: any) => row.id);

  const companyTimezone = JSON.parse(activeCompany).timeZone || '';

  const updatedRows = selectedRows.map((row) => {
    const updatedServerTime = new Date(new Date(row.servertime).getTime() + timeDifference).toISOString();
    const { date: updatedDate, time: updatedStartTime } = formatDateTime(updatedServerTime, companyTimezone);
    const updatedStartTimeFormated = convertTimeStringTo24HourFormat(updatedStartTime);
    const updatedDateFormated = formatDateWithLuxon(updatedDate);

    return { ...row, servertime: updatedServerTime, date: updatedDateFormated, startTime: updatedStartTimeFormated };
  });

  const updatedRowsMap = new Map(updatedRows.map((row) => [row.id, row]));
  const updatedData = data.map((row) => updatedRowsMap.get(row.id) || row);
  const transformedArray = selectedIds.flatMap((id: string) => [`${id}_startTime`, `${id}_date`]);

  setData(updatedData);
  setChangedCells((prev) => [...prev, ...transformedArray]);

};
